import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header'
import Footer from '../components/footer';

const CaseStudiesPage = () => {
    // Client-side Runtime Data Fetching
    const [caseStudies, setCaseStudies] = useState([])
    useEffect(() => {
        // get data from GitHub api
        fetch(`https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/case_study`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setCaseStudies(resultData);
            }) // parse JSON from request

    }, [])
  
    return (
        <>
        <Header/>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Case Studies - Productlabs</title>
            <link rel="canonical" href="https://productlabs.us/" />
            <meta property="og:title" content="Case Studies - Productlabs" />
            <meta property="og:description" content="VETTED EXPERTS TO HELP EXECUTE PRODUCT BUILD" />
        </Helmet>
        <div className='case-study-detail-page'>
            <div className="case-studies-section">
                <div className="container">
                    <div className="heading-wrapper">
                        <div className="heading-common-wrap">
                            <h2>Case Studies</h2>
                        </div>
                    </div>
                </div>
                    <div className="case-study-grid-data">
                    {caseStudies.map((caseStudy,index) => (
                        <div className="case-studies-module-wrap" key={index}>
                            <div className="container">    
                                <div className="case-studies-module">
                                    <div className="case-studies-image">
                                        <img src={caseStudy.yoast_head_json.og_image[0].url} alt="case-logo1"/>
                                    </div>
                                    <div className="case-studeies-content">
                                        <img src={caseStudy.acf.company_logo} className="company-logo" alt="case-logo" />
                                        <p className="case-studies-text"><span>Microservice:</span> {caseStudy.acf.micro_service}</p>
                                        <p className="case-studies-text"><span>Impact:</span> {caseStudy.acf.impact}</p>
                                        <p className="case-studies-text"><span>Duration:</span>  {caseStudy.acf.duration}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
            </div>
        </div>
        <Footer/>
        </>
        
    )
}
 
export default CaseStudiesPage;